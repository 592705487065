import { forceToInt } from '~/utils/numbers'

type RefOrNumber = Ref<number> | number

export interface FormatNumberOptions {
  min?: RefOrNumber
  max?: RefOrNumber
  format?: 'price' | 'number' | 'none'
  index?: number
  blankOnZero?: boolean
}

const defaultOptions: FormatNumberOptions = {
  min: 0,
  max: Infinity,
  format: 'number',
  index: 0,
  blankOnZero: false,
}

export function useFormatNumberInput(valueRef: Ref<number | string | number[]>, opts: FormatNumberOptions = {}) {
  const options = computed(() => {
    return Object.assign({}, defaultOptions, opts)
  })

  let formatFunction = (num: number) => `${num}`
  if (options.value.format === 'number') {
    formatFunction = formatNumber
  }
  else if (options.value.format === 'price') {
    formatFunction = formatPrice
  }

  const setValue = (v: number) => {
    if (Array.isArray(valueRef.value)) {
      valueRef.value[options.value.index as number] = v
    }
    else {
      valueRef.value = v
    }
  }

  const getValue = () => {
    return (Array.isArray(valueRef.value)) ? valueRef.value[options.value.index as number] : valueRef.value
  }

  return computed({
    get: () => {
      const result = formatFunction(getValue() as number)
      if (forceToInt(result) === 0 && options.value.blankOnZero) {
        return ''
      }
      return result
    },
    set: async (newValue: string) => {
      const min = toValue(options.value.min) as number
      const max = toValue(options.value.max) as number

      await nextTick(() => {
        setValue(Math.random())
      })

      let temp = forceToInt(newValue)

      if (max !== Infinity) {
        temp = Math.min(temp, max)
      }

      temp = Math.max(temp, min)

      nextTick(() => {
        setValue(temp)
      }).catch(err => console.log(err))
    },
  })
}
